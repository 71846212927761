@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .dark-mode {
    @apply dark:brightness-95 dark:-hue-rotate-180 dark:invert dark:saturate-100;
  }

  .dark-image-mode {
    @apply dark:hue-rotate-180 dark:invert dark:saturate-[1.25];
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.App-link {
  @apply text-blue-600;
}

@layer components {
  .scrollbar-primary {
    @apply scroll-m-2 scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-200 scrollbar-corner-sky-600;
  }

  .btn-primary {
    @apply flex w-full justify-center rounded-md border border-transparent bg-primary-default px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-mid focus:bg-primary-mid focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75;
  }

  .btn-primary-mid {
    @apply btn-primary bg-primary-mid hover:bg-primary-mid/80 focus:bg-primary-mid/80;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-sm font-medium text-primary-default hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-dark disabled:opacity-75;
  }
  .btn-cancel {
    @apply flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-white;
  }
  .onboarding-progress-container {
    @apply relative h-24 w-full overflow-x-auto bg-gradient-to-b from-primary-dark to-primary-default p-4 pt-8 md:h-32 lg:fixed lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/3 lg:p-8 lg:pt-16;
  }
  .otp-code-input {
    @apply h-12 w-12 rounded border-2 border-gray-400 bg-transparent text-center text-xl font-semibold text-gray-400 outline-none transition focus:border-primary-default focus:text-primary-default sm:h-16 sm:w-16 sm:text-3xl md:h-24 md:w-24 md:text-4xl;
  }
  .text-input-primary {
    @apply block min-h-[46px] appearance-none placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm;
  }

  .app-input-primary {
    @apply border-0 border-transparent !bg-transparent px-4 focus:border-transparent focus:ring-0;
  }

  .app-input-primary-inner-wrapper {
    @apply !bg-transparent;
  }

  .app-input-primary-wrapper {
    @apply items-center overflow-hidden border-[1.9px] border-primary-main/30 px-0 group-data-[focus=true]:border-primary-main/70 group-data-[hover=true]:border-primary-main/50;
  }

  .row-center {
    @apply flex items-center justify-between;
  }
  .row-start {
    @apply flex items-start justify-between;
  }

  .row-end {
    @apply flex items-center justify-end;
  }
  .row-start-start {
    @apply flex items-start justify-start;
  }

  .row-center-end {
    @apply flex items-center justify-end;
  }

  .row-center-start {
    @apply flex items-center justify-start;
  }

  .row-start-center {
    @apply flex items-start justify-between;
  }

  .inspect {
    @apply border-4 border-red-400 bg-red-400;
  }
  .inspect2 {
    @apply border-4 border-blue-400 bg-blue-400;
  }
  .inspect3 {
    @apply border-4 border-green-400 bg-green-400;
  }
  .icon-5 {
    @apply h-5 w-5;
  }
}

.swal2-container.swal2-center > .swal2-popup,
.swal2-container.swal2-top-end > .swal2-popup,
.swal2-container.swal2-top-right > .swal2-popup {
  padding: 1em 0.5em;
  margin: 1em 0;
}

.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0em 1em 0 0 !important;
  font-size: 0.8em !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
  color: #6a7270 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin: 0.5em 1em 0.5em 0 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  color: #101827 !important;
}

.swal2-popup.swal2-toast .swal2-close {
  align-self: flex-start !important;
  width: 0.8em;
  height: 0.8em;
  margin: 4px;
  font-size: 1.8em;
}

.swal2-popup.swal2-toast .swal2-icon {
  align-self: flex-start !important;
  margin: 0.5em 0.5em 0 0 !important;
}

.no-border {
  border: 0 !important;
}

.meeting-details-gradient-bg {
  background-image: url('https://content.app-sources.com/s/37885181206473252/uploads/Blog/Image-3533516.png') !important;
  /* background-image: url('/meeting-details-gradient-bg.png') !important; */
}

.dashboard-icon > div > span > svg {
  width: 35px !important;
  height: 35px !important;
}
.PhoneInputCountryIcon--border {
  background-color: none !important;
  box-shadow: none !important;
}

.PhoneInputCountryIcon {
  width: 25px !important;
  height: 15px !important;
}

#headlessui-dialog-panel-\:r9\:
  > div
  > div.bg-gray-50.border-gray-200.p-3.border.rounded-md
  > div:nth-child(2)
  > div:nth-child(1) {
  display: none !important;
}

.splide__pagination__page {
  width: 0.5rem !important;
  height: 0.375rem !important;
  background-color: rgb(153, 168, 167) !important;
  opacity: 1 !important;
}

.splide__pagination {
  bottom: -1em !important;
}

.splide__pagination__page.is-active {
  width: 1.2rem !important;
  height: 0.375rem !important;
  border-radius: 0.5rem !important;
  background-color: #0b6cf5 !important;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
